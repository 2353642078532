import { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected, mintNFT } from "./utils/interact.js";

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
 
  useEffect(async () => {
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);

    addWalletListener(); 
}, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => {
    const { status } = await mintNFT(url, name, description);
    setStatus(status);
};

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
        
        } else {
          setWallet("");
          setStatus("🦊 Conectate a Metamask antes de mintear.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" rel="noreferrer"  href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (
    <div  >
      <div class="container-fluid p-4 bg-warning text-white text-center">
  <h1>🐝 Brumbee NFT Minter</h1>
 
</div>
<nav class="navbar navbar-expand-sm bg-dark navbar-dark">
  <div class="container-fluid">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link active" href="#">Home</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https:\\brumbee.com.mx">BrumBee Suite</a>
      </li>
    
     
    </ul>
  </div>
</nav>
        <div className="Minter container">
      <button id="walletButton" style={{float: 'right'}} onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Conectado: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Conectar Wallet</span>
        )}
      </button>

      <br></br>
   
      <p>
        Llena los campos y despues presiona "Mintear NFT."
      </p>
      <form>
        <h3>Link a imagen IPFS: </h3>
        <input
          type="text"
          placeholder="URL de la imagen"
          onChange={(event) => setURL(event.target.value)}
        />
        <h3>Nombre del NFT: </h3>
        <input
          type="text"
          placeholder="Nombre del nft"
          onChange={(event) => setName(event.target.value)}
        />
        <h3>Descripción: </h3>
        <input
          type="text"
          placeholder="Descripción completa del nft"
          onChange={(event) => setDescription(event.target.value)}
        />
      </form>
      <button id="mintButton" onClick={onMintPressed}>
        Mintear NFT
      </button>
      <p id="status">
        {status}
      </p>
    </div>
    </div>
  );
};

export default Minter;
